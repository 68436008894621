import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Particle from "../Particle";
import TimeLineInfoCard from "./TimelineInfoCard";

const info = [
  {
    title: "School Newspaper Founder",
    dateStart: "September 2021",
    dateEnd: "June 2022	",
    content:
      "I decided to create a school newspaper for our school this year to keep students engaged, especially since activities have decreased due to covid. The newspaper includes school affairs and outside events such as politics, sports, etc., and has additional activities and competitions for every subject. I interviewed a group of people to select our new team. Currently, I am working on a newspaper website.I host weekly meetings after school. ",
  },

  {
    title: "Student Council President",
    dateStart: "September 2021",
    dateEnd: "Present",
    content:
      "I coordinate all activities and run meetings, representing all students in my school. I am the main initiator of an anti-bullying campaign, and the lead organizer of events and student development activities such as school breast cancer awareness day, diabetes day, beach clean-ups, winter wonderland carnival, etc. Additionally, I am the lead fundraiser. Engaged approximately, 60 community members.",
  },
  {
    title: "Beshara Group Company",
    dateStart: "May 2021",
    dateEnd: "August 2019",
    content:
      "I amended databases, worked alongside programmers to observe their work, help debug and create applications.I increased time efficiency as I was able to help spot bugs. I also helped develop a hospital portal GUI using object-oriented programming principles as part of an implementation of a digitized doctor application",
  },
  {
    title: "Deputy Head Girl",
    dateStart: "September 2020",
    dateEnd: "May 2021",
    content:
      "I was elected from both 11th and 12th grades to become the deputy head. Acting as an ambassador and role model, I organized and planned regular meetings with our committee while ensuring that decisions are carried out promptly. In addition, I worked with the Senior Leadership Team to create a duty rotation and ensured that all Senior Navigators are fulfilling their duties. I was managing a prefect committee consisting of 20 people.",
  },
  {
    title: "Online Tutoring",
    dateStart: "June 2020",
    dateEnd: "December 2020",
    content:
      "I decided to volunteer as an online tutor in an attempt to help those affected by the pandemic, especially to help students who found themselves struggling. My students ranged in ages 6-16 and I taught Maths, Science, French and English. On average, my students increased their grades by two grade points.",
  },
  {
    title: "Advocater and fundraiser",
    dateStart: "June 2020",
    dateEnd: "August 2020",
    content:
      "I set up a campaign page to accept donations to support the Luv Micheal company which employs autistic people    ",
  },

  {
    title: "Senior centre volunteer",
    dateStart: "January 2020",
    dateEnd: "March 2020	",
    content:
      "Took care of the elderly and kept them company. Made sure they took their medicine at the right times, ate well, etc. Additionally, I created crochet pieces for them and baked them weekly goods. ",
  },
  {
    title: "Harvard Model Congress in Dubai",
    dateStart: "January 2019",
    dateEnd: "January 2020",
    content:
      "I took part in 2 conferences (January 2019 and January 2020)I learned first-hand about governments and policy during the two sessions I attended; first as part of the Senate Intelligence Committee where we discussed Big Data and foreign interventions and the second year as a delegate of PR China where we discussed autonomous weapons and the Venezuelan crisis.",
  },
  {
    title: "Marketing coordinator trainee",
    dateStart: "October 2019",
    dateEnd: "March 2020",
    content:
      "I conducted research to analyze customers’ behaviour (e.g., purchasing habits, trends and preferences), encompassing 100 customers per week.I also designed and managed marketing online campaigns and organized weekly marketing staff meetings for buyers, assistant managers and merchandisers.",
  },

  {
    title: "Barista",
    dateStart: "June 2019",
    dateEnd: "August 2019",
    content:
      "I Provided excellent customer service to all patrons, averaging 200 drinks and 50 meal orders per day. Managed daily and weekly balance sheets with an average turnover of $1500 per day.",
  },

  {
    title: "Beach Clean up",
    dateStart: "November 2018",
    dateEnd: "November 2019",
    content:
      "I decided to design flyers which I shared around the school and social media to organize a beach clean up event with 50 members taking part. We were able to help clear the beach from any trash picking up about 3 full bags of trash",
  },
  {
    title: "Model United Nations",
    dateStart: "September 2018",
    dateEnd: "",
    content:
      "Member of MUN Club where I took part in activities related to the work of the UN, such as raising awareness about SDG goals and celebrating International UN Day.",
  },
  {
    title: "Sqush Player",
    dateStart: "August 2018",
    dateEnd: "Current",
    content:
      "After moving into my new house and finding a squash court I was determined to learn how to play. I taught myself in the beginning but then found it better to get a trainer. I like playing squash as it helps my strength, mind and overall mental state improve. I enjoy it because it requires both the mind and the body to be engaged.",
  },
  {
    title: "Community Residence Leader",
    dateStart: "June 2018",
    dateEnd: "Present",
    content:
      "I provided a babysitting service for 15 children in my apartment complex. Furthermore, I created educational and recreational activities (e.g., book club, entrepreneurship week, trick or treating…) for all residence in the complex",
  },
  {
    title: "Student Representitive from School council",
    dateStart: "September 2016",
    dateEnd: "May 2020",
    content:
      "I had been elected as a member of the Student Council for 4 consecutive years among 100 students. I worked democratically to present the student body in school decision-making and organize ways for students to participate and enjoy school life and manage the activities. The  SRC organized numerous successful events such as breast cancer awareness day, diabetes day, winter wonderland carnival, etc…also we made sure that the school was intact for example by ensuring that no bullying took place and by monitoring halls.",
  },
];

const education = [
  {
    title: "Gulf Eglish School",
    dateStart: "Sept 2009",
    dateEnd: "Present",
    content: ["Average Grade: A*"],
  },
];

const TimeLineInfo = () => {
  return (
    <Container fluid className="">
      <Particle />
      <Row className="resume">
        <Col md={12} className="resume-right text-start">
          <h3 className="resume-title">Education</h3>
          <TimeLineInfoCard
            title={education[0].title}
            date={`${education[0].dateStart} - ${education[0].dateEnd}`}
            content={[education[0].content]}
          />
        </Col>
        <Col md={12} className="resume-right text-start">
          <h3 className="resume-title">Extracurricular Activities</h3>
          {info.map(({ title, content, dateStart, dateEnd }, i) => (
            <TimeLineInfoCard
              key={i}
              title={title}
              date={dateStart && `${dateStart} - ${dateEnd}`}
              content={typeof content === "string" ? [content] : [...content]}
            />
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default TimeLineInfo;
