import React, { Linking, Button } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Monalight1 from "../../Assets/Monalight1.png";

import Home2 from "./Home2";
import Type from "./Type";
import Particle from "../Particle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import ThemeProvider from "styled-components";
import girlPhoto from "../../Assets/girl.png";
import { Styled } from "../styles";
function Home({ className }, props) {
  return (
    <section className={className}>
      <Container fluid className="home-section mt-5" id="home">
        <Particle />

        <Row>
          <Col md={7} className="home-header">
            <h1 style={{ paddingBottom: 15 }} className="heading">
              Hi There{" "}
              <span className="wave" role="img" aria-labelledby="wave">
                👋🏻
              </span>
            </h1>

            <h1 className="heading-name">
              I'M
              <strong className="main-name"> Mona Abusharkh</strong>
            </h1>

            <div style={{ padding: 50, textAlign: "left" }}>
              <Type />
            </div>
          </Col>

          <Col md={5} style={{ paddingBottom: 20 }}>
            <img
              src={Monalight1}
              size="1000"
              height="2000px"
              alt="home pic"
              style={{ marginTop: "100px" }}
              className="img-fluid h-50 "
            />
          </Col>
        </Row>
      </Container>

      <Home2 />
    </section>
  );
}

export default Styled(Home);
