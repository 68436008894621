import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";

import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { CgGitFork } from "react-icons/cg";
import { AiFillGithub } from "react-icons/ai";
import {
  AiFillStar,
  AiOutlineHome,
  AiOutlineFundProjectionScreen,
  AiOutlineUser,
} from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BiEnvelope, BiMoon, BiSun } from "react-icons/bi";
import { NavContainer, ThemeButton } from "../styles";
import { CgFileDocument } from "react-icons/cg";
import resume2 from "../Assets/resume2.pdf";
import { Styled } from "./styles";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const NavBar = (props) => {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <div className={props.className}>
      <Navbar
        expanded={expand}
        fixed="top"
        expand="md"
        className={navColour ? "sticky" : "navbar"}
      >
        <Container fluid>
          <Navbar.Brand href="/">
            <h1 className="purple">MA</h1>
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => {
              updateExpanded(expand ? false : "expanded");
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto" defaultActiveKey="#home">
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/"
                  onClick={() => updateExpanded(false)}
                  className="nav__item"
                  style={{ color: navColour ? "white" : undefined }}
                >
                  <AiOutlineHome
                    style={{ marginBottom: "2px", marginLeft: "20px" }}
                  />
                  Home
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/about"
                  onClick={() => updateExpanded(false)}
                  className="nav__item"
                  style={{ color: navColour ? "white" : undefined }}
                >
                  <AiOutlineUser style={{ marginBottom: "2px" }} />  {' '}About
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/project"
                  onClick={() => updateExpanded(false)}
                  className="nav__item"
                  style={{ color: navColour ? "white" : undefined }}
                >
                  <AiOutlineFundProjectionScreen
                    style={{ marginBottom: "2px" }}
                  />
                  {' '}Projects
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  href={resume2}
                  target="_blank"
                  rel="noreferrer"
                  className="nav__item"
                  style={{ color: navColour ? "white" : undefined }}
                >
                  <CgFileDocument style={{ marginBottom: "2px" }} /> Resume
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  href={"https://github.com/mona187"}
                  target="_blank"
                  rel="noreferrer"
                  className="nav__item"
                  style={{ color: navColour ? "white" : undefined }}
                >
                  <AiFillGithub style={{ marginBottom: "2px" }} />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  href={"mailto:monaabusharkh@gmail.com"}
                  target="_blank"
                  rel="noreferrer"
                  className="nav__item"
                  style={{ color: navColour ? "white" : undefined }}
                >
                  <FontAwesomeIcon
                    type="button"
                    icon={faEnvelope}
                    title="monaabusharkh@gmail.com"
                    href="mailto:monaabusharkh@gmail.com"
                  ></FontAwesomeIcon>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item className={!expand ? "mt-4" : ""}>
                <div>
                  <input
                    onChange={props.toggleTheme}
                    type="checkbox"
                    class="checkboxDark"
                    id="checkboxDark"
                  />
                  <label for="checkboxDark" class="labelDark">
                    <BiMoon color="#623686" />
                    <BiSun color="yellow" />
                    <div class="ball" />
                  </label>
                </div>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Styled(NavBar);
