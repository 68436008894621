// react
import React, { useState, useEffect } from "react";

// components
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home.js/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer";

// react-router
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// style

import "bootstrap/dist/css/bootstrap.min.css";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "../src/styles";
import "../src/styles.css";
import "./App.css";
import ScrollToTop from "./ScrollToTop";
import { Container } from "react-bootstrap";
const theme = {
  light: {
    mainColor: "#black",
    backgroundColor: "white",
  },
  dark: {
    mainColor: "white",
    backgroundColor: "#293241",
  },
};
function App() {
  const [load, upadateLoad] = useState(true);
  const [currentTheme, setCurrentTheme] = useState("light");

  const toggleTheme = () => {
    setCurrentTheme(currentTheme === "light" ? "dark" : "light");
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <ThemeProvider theme={theme[currentTheme]}>
          <GlobalStyle />
          <Navbar currentTheme={currentTheme} toggleTheme={toggleTheme} />
          <ScrollToTop />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/project" component={Projects} />
            <Route path="/about" component={About} />
            {/* <Route path = "/demo" component {video}></Route> */}
          </Switch>
          <Footer />
        </ThemeProvider>
      </div>
    </Router>
  );
}

export default App;
