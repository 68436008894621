import React, { Linking } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AiFillGithub } from "react-icons/ai";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer">
      {/*   <Row>
        <Col md="4" className="footer-copywright" color="white">
          <h3>Copyright © {year} Mona Abusharkh</h3>
        </Col>
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href="https://github.com/mona187"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillGithub />
              </a>
            </li>
            <a href="mailto:monaabusharkh@gmail.com">
              <FontAwesomeIcon
                type="button"
                icon={faEnvelope}
                size="50px"
                color="white"
                onPress={() => Linking.openURL("email@gmail.com")}
                title="monaabusharkh@gmail.com"
              >
                {" "}
              </FontAwesomeIcon>
            </a>
          </ul>
        </Col>
      </Row> */}
    </Container>
  );
}

export default Footer;
