import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Mona Abusharkh </span>
            <br />I am a 12th Grade Student doing my A-levels. Through this
            website, I hope that you learn about my technical skills and get a
            glimpse at who I am as a person.
            <br />
            <br />
            I am a creative, observant, positive and down to earth.I have always
            loved stepping out of my comfort zone and trying new things
            <br />
            My favourite things to do are:
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Solving Soduko/puzzles
            </li>
            <li className="about-activity">
              <ImPointRight /> Journal,I own 3 different journals
            </li>

            <li className="about-activity">
              <ImPointRight /> Reading
            </li>
            <li className="about-activity">
              <ImPointRight /> Craft(eg.crochet,sewing,paint,create things from
              scratch etc ..)
            </li>
            <li className="about-activity">
              <ImPointRight /> Cooking/Baking
            </li>
            <li className="about-activity">
              <ImPointRight />
              Sports (eg.Squash,Basketball,yoga)
            </li>
            <li className="about-activity">
              <ImPointRight /> Learning new languages
            </li>
          </ul>

          <p style={{ marginBlockEnd: 0, color: "rgb(155 126 172)" }}>
            " Imagination is more important than knowledge. Knowledge is
            limited. Imagination encircles the world "
          </p>
          <footer className="blockquote-footer">Einstein</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
