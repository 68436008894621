import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { BiVideo } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import { AiFillGithub } from "react-icons/ai";
import { CgDetailsMore } from "react-icons/cg";
function ProjectCards(props) {
  const [hovered, setHovered] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [descOpen, setDescOpen] = React.useState(false);
  return (
    <>
      <Modal
        show={modalOpen}
        scrollable={true}
        centered={true}
        onBackdropClick={() => setModalOpen(false)}
        dialogClassName="video_modal_container"
        containerClassName="video_modal_container_out"
        onHide={() => setModalOpen(false)}
        contentClassName="video_modal_container_out"
      >
        <div className="row py-0 my-0">
          <video width="700" height="400" controls autoPlay>
            <source src={props.videoPath} type="video/mp4"></source>
          </video>
        </div>
      </Modal>
      <Modal
        show={descOpen}
        scrollable={true}
        centered={true}
        onBackdropClick={() => setDescOpen(false)}
        dialogClassName="video_modal_container"
        containerClassName="video_modal_container_out"
        onHide={() => setDescOpen(false)}
        contentClassName="video_modal_container_out"
      >
        <div className="row py-4 my-2 mx-3">
          <p>{props.description}</p>
        </div>
      </Modal>
      <div
        className="col-md-4 my-2"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <h6>{props.title}</h6>
        <div
          style={{
            position: "relative",
            backgroundImage: `url(${props.imgPath})`,
            height: "200px",
            borderRadius: "10px",
            backgroundSize: "cover",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: "32%",
              transition: ".1s",
              top: "50%",
              opacity: !hovered ? "0" : 1,
              transition: "opacity .4s ease-out",
            }}
            className="align-self-center"
          >
            <div>
              <Button
                className={
                  "card-button mx-1 text-center justify-content-center "
                }
                onClick={() => {
                  return setModalOpen(true);
                  return window.open(
                    "https://" +
                      String(props.link)
                        .replace("https//", "")
                        .replace("http://", "") || "",
                    "Data",
                    "height=400,width=800"
                  );
                }}
                target="_blank"
              >
                <BiVideo className="align-self-center text-center ms-1" />{" "}
                &nbsp;
              </Button>

              <Button
                className={"card-button  text-center"}
                onClick={() => {
                  return setDescOpen(true);
                }}
              >
                <CgDetailsMore className="ms-1" /> &nbsp;
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
    /*   <Card className="col-md-4">
      <Card.Img
        variant="top"
        src={props.imgPath}
        alt="card-img"
        height={"200px"}
        style={{ width: "100%" }}
      />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}></Card.Text>
        <Button
          className={"card-button"}
          onClick={() => {
            return window.open(
              "https://" +
                props.link.replace("https//", "").replace("http://", "") || "",
              "Data",
              "height=400,width=800"
            );
          }}
          target="_blank"
        >
          <BiLinkExternal /> &nbsp;
        </Button>
      </Card.Body>
    </Card> */
  );
}
export default ProjectCards;
