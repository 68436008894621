import React from "react";

const TimeLineInfoCard = (props) => {
  console.log({ content: props.content });
  return (
    <div className="resume-item">
      {props.title && (
        <h5 className={props.title ? "resume-title" : "resume-no-title"}>
          {props.title}
        </h5>
      )}
      {props.date && (
        <p>
          <em>{props.date}</em>
        </p>
      )}
      {props.content[0] && (
        <ul>
          {props.content.map((value, index) => (
            <li key={index}> ‣ {value}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TimeLineInfoCard;
