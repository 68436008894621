import styled, { createGlobalStyle } from "styled-components";

export const NavContainer = styled.div`
  text-align: "right";
`;

export const TextStyle = styled.div`
  font-size: "700px";
`;
export const ThemeButton = styled.button`
  font-size: 1em;
  margin: 1.25em;
  padding: 0.25em 1em;
  border-radius: 3px;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
`;
export const GlobalStyle = createGlobalStyle`
body{
    color: ${(props) => {
      return props.theme.mainColor;
    }};
    background-color: ${(props) => props.theme.backgroundColor};
}
`;
