import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import about from "../../Assets/about.png";
import Tilt from "react-parallax-tilt";

import { Styled } from "../styles";
function Home2(className) {
  return (
    <div
      className={`${className} container`}
      style={{ wordWrap: "break-word", wordBreak: "break-word" }}
    >
      <Container fluid className="home-about-section" id="about">
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MY PROGRAMMING
              HISTORY
            </h1>
            <p
              className="home-about-body"
              style={{ wordBreak: "break-word", wordWrap: "break-word" }}
            >
              I decided to embark on this wonderful tech journey inspired by the
              numerous coders around me. Coding was love at first sight for me,
              as I felt an instantaneous pull to learn its secrets and add my
              creative twists to it. In early 2021 I began teaching myself
              Python and soon afterward I joined an intense full-stack
              development bootcamp which taught me the following:
              <br />
              <br />
              <i>
                <b className="purple">
                  Javascript,React,React
                  Native,Node.JS,Express,HTML,CSS,bootstrap
                </b>
              </i>
              <br />
              <br />
              MY FAVOURITE PART WAS ... &nbsp;
              <br />
              <i>
                <b className="purple">React native and Express </b>
                <br />
                <b className="purple"></b>
                Which might be a bit surprising considering the fact that people
                might dislike React Native... because it does not stop with the
                constant errors, and that is at times not the coder’s fault!
              </i>
              <br />
              <br />
              Whenever possible, I try to go on Code wars to improve my skills
              in java script
              <i>
                <b className="purple"> What I want to improve on most</b>
              </i>
              &nbsp; is
              <b />
              <i>
                <b className="purple">
                  {" "}
                  Styling &#38; User interferance/User interaction
                </b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={about} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Styled(Home2);
