import styled, { createGlobalStyle } from "styled-components";

export const NavContainer = styled.div`
  text-align: "right";
`;

export const TextStyle = styled.div`
  font-size: "700px";
`;
export const ThemeButton = styled.button`
  font-size: 1em;
  margin: 1.25em;
  padding: 0.25em 1em;
  border-radius: 3px;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
`;
export const GlobalStyle = createGlobalStyle`
body{
    color: ${(props) => props.theme.mainColor};
    background-color: ${(props) => props.theme.backgroundColor};
}
`;

export const Styled = (Component) => {
  return styled(Component)`
    color: ${(props) => props.theme.mainColor};
    .main-name {
      color: #cd5ff8;
    }

    .home-content {
      color: ${(props) => props.theme.mainColor};
      text-align: left;
      padding: 9rem 0 2rem !important;
    }

    .heading-name {
      color: ${(props) => props.theme.mainColor};
      font-size: 2.5em !important;
      padding-left: 45px !important;
    }

    .heading {
      font-size: 2.4em !important;
      padding-left: 50px !important;
    }

    .home-about-section {
      color: ${(props) => props.theme.mainColor};
      position: relative;
 /*      padding-bottom: 70px !important;
      padding-top: 70px !important; */
    }

    .home-about-description {
      color: ${(props) => props.theme.mainColor};
      padding-top: 20px !important;
      padding-bottom: 20px !important;
      text-align: center;
    }

    .nav__item {
      color: ${(props) => props.theme.mainColor};
    }

    .sticky {
      background-color: #212038d8 !important;
      transition: all 0.3s ease-out 0s !important;
      box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
      backdrop-filter: blur(15px) !important;
    }

    .navbar {
      color: ${(props) => props.theme.mainColor};
      transition: all 0.3s ease-out 0s !important;
      padding: 0.3rem 2rem !important;
      font-size: 1.2rem !important;
    }
    .navbar-nav .nav-item a::after {
      content: "";
      position: relative;
      display: block;
      height: 5px;
      width: 0;
      border-radius: 16px;
      background: #c95bf5;
      bottom: 1px;
      left: 0;
      z-index: -1;
      transition: all 0.3s ease-out 0s;
      color: ${(props) => props.theme.mainColor};
    }

    .navbar-nav .nav-item a:hover::after {
      width: 100%;
      color: ${(props) => props.theme.mainColor};
    }

    .nav-item::hover {
      color: ${(props) => props.theme.mainColor};
    }
    .sticky .nav__item {
      color: ${(props) => props.theme.mainColor};
    }
    .nav__item {
      color: ${(props) => props.theme.mainColor};
    }
    .nav__item::hover {
      color: ${(props) => props.theme.mainColor} !important;
    }

    .nav__item:hover {
      color: ${(props) => props.theme.mainColor} !important;
    }

    .navbar-nav .nav-link {
      color: ${(props) => props.theme.mainColor};
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }

    .nav-link {
      padding: 0.8rem 1rem !important;
      color: ${(props) => props.theme.mainColor};
    }

    .navbar-nav .nav-item {
      color: ${(props) => props.theme.mainColor};
      position: relative;
      margin-left: 20px;
    }

    .navbar-nav .nav-item a {
      color: ${(props) => props.theme.mainColor};
      font-weight: 400;
      transition: all 0.3s ease-out 0s;
      position: relative;
      z-index: 1;
    }

    .navbar-toggler {
      color: ${(props) => props.theme.mainColor};
      padding: 0.25rem 1.5rem !important;
      position: relative !important;
      background-color: transparent !important;
      border-color: transparent !important;
    }

    .navbar-toggler span {
      display: block !important;
      background-color: #be50f4 !important;
      height: 4px !important;
      width: 27px !important;
      margin-top: 5px !important;
      margin-bottom: 5px !important;
      transform: rotate(0deg) !important;
      left: 0 !important;
      opacity: 1 !important;
      color: "white" !important;
    }

    .navbar-toggler:focus,
    .navbar-toggler:active {
      outline: 0 !important;
      color: "white" !important;
    }

    .navbar-toggler span:nth-child(1),
    .navbar-toggler span:nth-child(3) {
      transition: transform 0.35s ease-in-out !important;
      transition: transform 0.35s ease-in-out !important;
      color: "white" !important;
    }

    .navbar-toggler:not(.collapsed) span:nth-child(1) {
      position: absolute !important;
      left: 12px !important;
      top: 10px !important;
      transform: rotate(135deg) !important;
      opacity: 0.9 !important;
      color: "white" !important;
    }

    .navbar-toggler:not(.collapsed) span:nth-child(2) {
      height: 12px !important;
      visibility: hidden !important;
      background-color: transparent !important;
      color: "white" !important;
    }

    .navbar-toggler:not(.collapsed) span:nth-child(3) {
      position: absolute !important;
      left: 12px !important;
      top: 10px !important;
      transform: rotate(-135deg) !important;
      opacity: 0.9 !important;
      color: "white" !important;
    }

    .navbar-brand {
      color: rgb(250, 250, 250) !important;
      color: black;
    }

    .logo {
      height: 1.4em !important;
      width: 2.5em !important;
    }

    .purple {
      color: var(--imp-text-color) !important;
    }

    .navbar-toggler:not(.collapsed) .nav__item {
      color: "white" !important;
    }
  `;
};

export const AboutMeStyled = (Component) => {
  return styled(Component)`
    margin-top: 50px;
    .about-section {
      position: relative !important;
      padding-top: 150px !important;
      padding-bottom: 30px !important;
      background-image: var(--section-background-color) !important;
      color: black !important;
    }

    .about-activity {
      list-style: none !important;
      text-align: left !important;
      padding-left: 1px !important;
      font-size: 20px;
    }

    .blockquote-footer {
      margin-top: 2px;
      color: rgb(155 126 172) !important;
      text-align: end;
    }

    .quote-card-view {
      border: none !important;
      color: ${(props) => props.theme.mainColor};
      background-color: transparent !important;
    }
    .purple {
      color: var(--imp-text-color) !important;
    }
  `;
};

export const ProjectsStyled = (Component) => {
  return styled(Component)`
    color: ${(props) => props.theme.mainColor};
    margin-top: 70px !important;
    position: relative !important;

    padding-bottom: 30px !important;
    background-image: var(--section-background-color) !important;
    .purple {
      color: var(--imp-text-color) !important;
    }
    .project-heading {
      color: ${(props) => props.theme.mainColor} !important;
    }
    .project-card {
      color: ${(props) => props.theme.mainColor};
      padding-top: 50px !important;
      padding-bottom: 50px !important;
      padding-left: 25px !important;
      padding-right: 25px !important;
      height: auto !important;
    }

    .project-card-view {
      color: ${(props) => props.theme.mainColor};
      box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
      color: black !important;
      background-color: transparent !important;
      opacity: 0.9 !important;
      transition: all 0.5s ease 0s !important;
      height: 100% !important;
    }
    .project-card-view:hover {
      color: ${(props) => props.theme.mainColor};
      transform: scale(1.02) !important;
      overflow: hidden !important;
      box-shadow: 0 4px 4px 5px rgba(129, 72, 144, 0.561) !important;
    }

    .video_modal_container {
      width: 90vw !important;
      max-width: 90vw !important;
    }
  `;
};
