import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AboutMeStyled } from "../styles";

import Aboutcard from "./AboutCard";
import TimeLineInfo from "./TimelineInfo";
const About = ({ className }) => {
  return (
    <Container fluid className={className}>
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={10}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Know Who <strong className="purple">I AM</strong>
            </h1>
            <Aboutcard />
          </Col>
        </Row>
        <TimeLineInfo />
      </Container>
    </Container>
  );
};

export default AboutMeStyled(About);
